import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import com from "./com";
import config from "./config";
import { setStore, getStore } from "../util/store";
Vue.use(Vuex);
const _list = getStore({ name: "playlist", type: "session" });
export default new Vuex.Store({
  state: {
    playList: _list ? JSON.parse(_list).list : {},
    playId: _list ? JSON.parse(_list).playId : null,
    routerKey: 0,
    sort: 0,
    playDate: 0,
  },
  mutations: {
    ADD_PLAYLIST(state, d) {
      function add(o) {
        state.sort = state.sort + 1;
        o.sort = state.sort;
        state.playList[o.id] = o;
      }
      let playId = null;
      if (d.constructor === Array) {
        for(let i=d.length;i--;){
          i === 0 && (playId = d[i].id);
          add(d[i]);
        }
        // d.forEach((item, index) => {
        //   index === 0 && (playId = item.id);
        //   add(item);
        // });
      } else {
        playId = d.id;
        add(d);
      }
      state.playList = { ...state.playList };
      state.playId = playId;
      state.playDate = new Date().getTime();
      setStore({
        name: "playlist",
        content: JSON.stringify({ list: state.playList, playId: state.playId }),
        type: "session",
      });
    },
    DEL_PLAYLIST(state, { d, playIndex }) {
      let key = d.id + "";
      if(!state.playList[key])return
      let playKey = playIndex + "";
      if (playKey == key) state.playId = null;
      delete state.playList[key];
      state.playList = { ...state.playList };
      setStore({
        name: "playlist",
        content: JSON.stringify({ list: state.playList, playId: state.playId }),
        type: "session",
      });
    },

    SET_PLAYID(state, d) {
      state.playId = d;
      setStore({
        name: "playlist",
        content: JSON.stringify({ list: state.playList, playId: state.playId }),
        type: "session",
      });
    },

    SET_ROUTER_KEY(state) {
      state.routerKey = new Date().getTime();
    },
  },
  actions: {},
  modules: {
    user,
    com,
    config,
  },
});
