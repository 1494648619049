<template>
  <div class="app-play" :class="{ over: show, out: !show }" ref="appPlay">
    <div class="open">
      <span @mouseover="mouseoverLock" @click="lock = !lock">
        <img v-if="lock" src="../assets/audio/suo1.png" />
        <img v-else src="../assets/audio/suo.png" />
      </span>
    </div>
    <div class="main">
      <div>
        <cd-audio></cd-audio>
      </div>
    </div>
  </div>
</template>
<script>
import cdAudio from "./cd-audio";
export default {
  data() {
    return {
      show: false,
      lock: false,
      timer: null,
    };
  },
  // computed: {
  //   list() {
  //     return this.$store.state.playList;
  //   },
  // },
  watch: {
    "$store.state.playList": {
      handler() {
        if (this.lock) return;
        this.show = true;
        this.timer = setTimeout(() => {
          this.show = false;
        }, 3000);
      },
    },
  },
  methods: {
    mouseoverLock(event) {
      if (this.lock) event.stopPropagation;
    },
  },
  mounted() {
    document.addEventListener("mouseover", () => {
      if (!this.timer && !this.lock) this.show = false;
    });
    this.$refs.appPlay.addEventListener("mouseover", (e) => {
      // if (this.lock) return;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.show = true;
      e.stopPropagation();
    });
  },
  components: {
    cdAudio,
  },
};
</script>
<style lang="scss" scoped>
@import "../style/css";
$overBottom: 0px;
$outBottom: -53px;

.over {
  //animation: over 1s;
  bottom: $overBottom !important;
}
.out {
  //animation: out 1s;
  bottom: $outBottom !important;
}
.app-play {
  width: 100%;
  height: 73px;
  position: fixed;
  left: 0;
  bottom: $outBottom;
  transition: bottom 1s;
  z-index: 999;
  //opacity: .86;
}
.open {
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  opacity: 0.86;
  span {
    display: inline-block;
    width: 50px;
    height: 20px;
    //background:#333333 ;
    margin-right: 150px;
    > img {
      height: 100%;
    }
  }
}
.main {
  height: 53px;
  background: rgba(51, 51, 51, 0.86);
  box-shadow: 0 6px 12px 0 rgba(129, 129, 129, 0.57);
  //opacity: 0.86;
  > div {
    width: $containerWidth;
    margin: auto;
  }
}
</style>
