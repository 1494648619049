import axios from "axios";
// import store from "@/store";
import { Message } from "element-ui";
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
});

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.withCredentials = true;

// 请求拦截器
// service.interceptors.request.use(
//   function (config) {
//     config.headers['Cookie'] = 'JSESSIONID=133E701365802590A68549149802A6F2'
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // config.headers['Cookie'] = 'JSESSIONID=133E701365802590A68549149802A6F2'
    // }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// // 响应拦截器
service.interceptors.response.use(
  function (response) {
    // if (response.data.code !== 200) {
    //   Message.error(response.data.msg);
    // }
    // console.log(response)
    let noArr = ["verifyCode", "phone", "email", "password"];
    // console.log(response.config)
    let api = response.config.url.split("/").pop();
    if (noArr.indexOf(api) !== -1) {
      console.log(response.data.msg);
    } else if (response.data.code >= 400 && response.data.code <= 500) {
      Message.error(response.data.msg);
    }
    return response.data;
  },
  function (error) {
    // console.log(error)
    // Message.error(error);
    return Promise.reject(error);
  }
);

export default service;
