<template>
  <div id="app">
    <router-view />
    <app-play></app-play>
  </div>
</template>
<script>
import appPlay from "@/components/app-play";
export default {
  data() {
    return {};
  },
  components: {
    appPlay,
  },
  async created() {
    this.$store.dispatch("GetSendHeaderList");
    await this.$store.dispatch("getUserInfo");
    this.$store.dispatch("GetUnLookNum");
  },
};
</script>
<style lang="scss">
* {
  padding: 0px;
  margin: 0px;
}
body {
  overflow-y: scroll;
}
</style>
