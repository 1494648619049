import Vue from "vue";
import VueRouter from "vue-router";
// import appContainer from "@/components/app-container";
import container from "@/views/container";
import permission from "./permission";
import "./prototype";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "container",
    component: container,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home"),
      },
      {
        path: "/muster/:id",
        name: "muster",
        // redirect:"/muster/:id/muster",
        component: () => import("@/views/muster"),
        // children:[
        //   {
        //     path:"/muster/:id/muster",
        //     component: ()=>import('@/views/muster'),
        //   }
        // ],
      },
      {
        path: "/singleDetail/:id",
        name: "singleDetail",
        component: () => import("@/views/single-detail"),
      },
      {
        path: "/songsDetail/:id",
        name: "songsDetail",
        component: () => import("@/views/songs-detail"),
      },
      {
        path: "/set",
        name: "set",
        component: () => import("@/views/set"),
        meta: {
          needLogin: true,
        },
      },

      {
        path: "/upload",
        name: "upload",
        component: () => import("@/views/upload"),
        meta: {
          needLogin: true,
        },
      },
      {
        path: "/information",
        name: "information",
        component: () => import("@/views/information"),
        meta: {
          needLogin: true,
        },
      },

      {
        path: "/copyrightDescription",
        name: "copyrightDescription",
        component: () => import("@/views/about/copyright-description"),
      },
      {
        path: "/contactUs",
        name: "contactUs",
        component: () => import("@/views/about/contact-us"),
      },
      {
        path: "/privacyTreaty",
        name: "privacyTreaty",
        component: () => import("@/views/about/privacy-treaty"),
      },
      {
        path: "/suggestion",
        name: "suggestion",
        component: () => import("@/views/about/suggestion"),
      },
      {
        path: "/userTreaty",
        name: "userTreaty",
        component: () => import("@/views/about/user-treaty"),
      },
    ],
  },
  {
    path: "/repassword",
    component: () => import("@/views/repassword"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/search"),
  },
  {
    path: "/personal",
    name: "personal",
    component: () => import("@/views/personal"),
    meta: {
      needLogin: true,
    },
  },
  {
    path: "/personalSongsDetail",
    name: "personalSongsDetail",
    component: () => import("@/views/personal-songs-detail"),
    meta: {
      needLogin: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
permission(router);
export default router;
