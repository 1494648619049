<template>
  <div style="min-height: 100%">
    <app-header></app-header>
    <app-container>
      <router-view :key="routerKey" />
    </app-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import appContainer from "@/components/app-container.vue";
import appHeader from "@/components/app-header.vue";
import AppFooter from "@/components/app-footer";

export default {
  name: "Home",
  components: {
    AppFooter,
    appContainer,
    appHeader,
  },
  computed: {
    routerKey() {
      return this.$store.state.routerKey;
    },
  },
};
</script>
