import axios from "@/util/axios";

/*
 * 注册
 * */
export const register = (params) => {
  return axios({
    url: "/api/register",
    method: "POST",
    data: {
      ...params,
    },
  });
};

/*
 * 退出登录
 * */
export const logout = (params) => {
  return axios({
    url: "/api/logout",
    method: "GET",
    params: {
      ...params,
    },
  });
};
/*
 * 登录
 * */

export const login = (params) => {
  return axios({
    url: "/api/login",
    method: "POST",
    params: {
      ...params,
    },
  });
};
/*
 * 快捷登录
 * */

export const loginFast = (params) => {
  return axios({
    url: "/api/loginFast",
    method: "POST",
    params: {
      ...params,
    },
  });
};
/*
 * 获取手机验证码
 * */
export const sendPhoneCode = (params) => {
  return axios({
    url: "/api/sendPhoneCode",
    method: "POST",
    params: {
      ...params,
    },
  });
};
/*
 * 获取邮箱验证码
 * */
export const sendEmailCode = (params) => {
  return axios({
    url: "/api/sendEmailCode",
    method: "POST",
    params: {
      ...params,
    },
  });
};

/*
 * 找回密码获取手机验证码
 * */
export const sendPhoneCodeForget = (params) => {
  return axios({
    url: "/api/sendPhoneCodeForget",
    method: "POST",
    params: {
      ...params,
    },
  });
};
/*
 * 找回密码获取邮箱验证码
 * */
export const sendEmailCodeForget = (params) => {
  return axios({
    url: "/api/sendEmailCodeForget",
    method: "POST",
    params: {
      ...params,
    },
  });
};
/*
 * 验证验证码
 * */
export const checkCode = (params) => {
  return axios({
    url: "/api/verifyCode",
    method: "POST",
    params: {
      ...params,
    },
  });
};

/*
 * 获取用户信息
 * */
export const sendUserInfo = (params) => {
  return axios({
    url: "/api/sysuser/detail",
    method: "GET",
    params: {
      ...params,
    },
  });
};

/*
 * 修改密码
 * */
export const sendUpPassword = (params) => {
  return axios({
    url: "/api/password",
    method: "PUT",
    params: {
      ...params,
      type: "front",
    },
  });
};

/*
 * 找回密码
 * */
export const sendRePassword = (params) => {
  return axios({
    url: "/api/reset",
    method: "PUT",
    params: {
      ...params,
    },
  });
};

/*
 * 绑定手机号
 * */
export const sendBindPhone = (params) => {
  return axios({
    url: "/api/phone",
    method: "PUT",
    params: {
      ...params,
    },
  });
};
/*
 * 绑定/解绑邮箱
 * */
export const sendBindEmail = (bind, params) => {
  let url = bind ? "/api/email" : "/api/unemail";
  return axios({
    url: url,
    method: "PUT",
    params: {
      ...params,
    },
  });
};

/*
 * 保存用户信息
 * */
export const sendSavaUserInfo = (params) => {
  return axios({
    url: "/api/sysuser/update",
    method: "POST",
    data: {
      ...params,
    },
  });
};
