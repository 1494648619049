import store from "@/store/index";
export default (router) => {
  router.beforeEach(async (to, from, next) => {
    if (to.meta.needLogin) {
      try {
        console.log(123);
        await store.dispatch("getUserInfo");
        if (store.state.user.userInfo) next();
        else next("/404");
      } catch (err) {
        next("/404");
      }
      // store
      //   .dispatch("getUserInfo")
      //   .then(() => {
      //     store.state.user.userInfo ? next() : next("/404");
      //   })
      //   .catch(() => next("/404"));
    } else {
      next();
    }
  });
};
