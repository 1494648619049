import axios from "@/util/axios";

export const headerList = (params) => {
  return axios({
    url: "/api/biztype/list",
    method: "GET",
    params: {
      size: 999,
      ...params,
    },
  });
};
