export const resetLoading = (vm) => {
  const fn = vm.prototype.$loading;
  vm.prototype.$loading = function () {
    return fn({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
  };
};
