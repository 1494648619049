<template>
  <div class="register-container">
    <div class="head">
      <div class="t">新用户注册</div>
      <div class="close" @click="$emit('close')">
        <i class="el-icon-close" />
      </div>
    </div>

    <div class="main">
      <!--    账号登陆   -->
      <el-form
        class="step0"
        v-if="step === 0"
        :model="form"
        ref="form"
        :rules="rules"
      >
        <el-form-item label="手机号或邮箱" prop="phone">
          <el-input
            class="diy-input"
            v-model="form.account"
            placeholder="请输入手机号或邮箱"
          ></el-input>
          <!--          <com-input v-model="form.phone" ></com-input>-->
        </el-form-item>

        <el-form-item label="设置密码" prop="password">
          <el-input
            class="diy-input"
            v-model="form.password"
            show-password
            placeholder="密码由6-16位的字母、数字、符号组成"
          ></el-input>
          <!--          <com-input v-model="form.account" ></com-input>-->
        </el-form-item>

        <el-form-item label="验证码" prop="code">
          <div style="display: flex; width: 100%">
            <el-input
              style="margin-right: 10px"
              class="diy-input"
              v-model="form.code"
              placeholder="请输入右侧验证码"
            ></el-input>
            <util-code :identifyCode.sync="identifyCode"></util-code>
          </div>
        </el-form-item>

        <!--        <el-form-item label="保密邮箱" prop="email">-->
        <!--          <el-input-->
        <!--            class="diy-input"-->
        <!--            v-model="form.email"-->
        <!--            placeholder="用于密码找回，注册号不能修改"-->
        <!--          ></el-input>-->
        <!--        </el-form-item>-->

        <el-form-item>
          <!--          <el-button type="primary" @click="submitForm('numberValidateForm')">登录</el-button>-->
          <com-button size="big" @click="next" class="next">下一步</com-button>
        </el-form-item>

        <!--        <el-form-item>-->

        <el-checkbox v-model="agree">
          勾选代表你同意
          <!--          /#userTreaty-->
          <a
            href="https://contracts.chaodj.com.cn/cyhweb/userAgreement/zh_cn.html"
            target="_blank"
            style="color: #c20c0c"
            >《潮音汇用户服务协议》</a
          >
          <!--          /#privacyTreaty-->
          <a
            href="https://contracts.chaodj.com.cn/cyhweb/privacyPolicy/zh_cn.html"
            target="_blank"
            style="color: #c20c0c"
            >《隐私声明》</a
          >
        </el-checkbox>
        <div class="bottom">
          <span></span>
          <span class="to-register">
            已有账号？<span @click="$emit('toLogin')">立即登录</span></span
          >
        </div>
        <!--        </el-form-item>-->
      </el-form>
      <div class="step1" v-else>
        <div class="step1-t">输入{{ form.phone ? "短信" : "邮箱" }}验证码</div>
        <div class="step1-tip" v-if="form.phone">
          我们已经给手机<span>+86 {{ form.phone }}</span>
          发送一个6位数字验证短信
        </div>
        <div class="step1-tip" v-if="form.email">
          我们已经给邮箱<span> {{ form.email }}</span>
          发送一个6位数字验证短信，如未收到请检查是否在“垃圾邮件”中
        </div>
        <el-input
          class="diy-input"
          placeholder="输入验证码"
          v-model="code"
        ></el-input>
        <div class="phone-code-time">
          <span v-if="time" class="time">{{ time }}s</span>
          <span v-else class="re-send" @click="reSend">重新发送</span>
        </div>
        <com-button size="big" @click="submit" class="next"
          >同意并注册</com-button
        >
        <div class="bottom">
          <span></span>
          <span class="to-register">
            已有账号？<span @click="$emit('toLogin')">立即登录</span></span
          >
        </div>
      </div>
    </div>
    <div class="bottom-image">
      <img src="../../assets/login.png" />
    </div>
  </div>
</template>
<script>
import comButton from "@/components/com/com-button";
import utilCode from "@/components/util/util-code";
import { isMobile, isEmail, validatePassword } from "@/util/validate";
export default {
  components: {
    // comInput,
    comButton,
    utilCode,
  },
  data() {
    return {
      agree: false,
      form: {
        phone: "",
        password: "",
        code: "",
        email: "",
        account: "",
        // phoneCode: "",
      },
      identifyCode: "",
      code: "",
      rules: {
        // phone: [
        //   { required: true, message: "请输入手机号", trigger: "blur" },
        //   // { validator: isPhone,message: "请输入手机号", trigger: 'blur' }
        // ],
        // password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      step: 0,
      time: 0,
    };
  },
  methods: {
    next() {
      this.agree
        ? this.check()
        : this.$message.error("请勾选《潮音汇用户服务协议》《隐私声明》");
    },
    check() {
      this.form.phone = null;
      this.form.email = null;
      let { account, password, code } = this.form;
      let { identifyCode } = this;
      if (!isMobile(account) && !isEmail(account)) {
        this.$message.error("请输入正确的手机号或邮箱");
        return;
      }
      if (!validatePassword(password)) {
        //validatenull(password) || password.length<6 || password.length>16
        this.$message.error("密码为6-16位，需组合数字、字母和符号");
        return;
      }
      if (code !== identifyCode) {
        this.$message.error("验证码有误");
        return;
      }
      if (isMobile(account)) {
        this.form.email = "";
        this.form.phone = account;
      }
      if (isEmail(account)) {
        this.form.phone = "";
        this.form.email = account;
      }
      this.getCode();
    },
    reSend() {
      this.getCode();
    },
    //  请求手机验证吗
    getCode() {
      let { phone, email } = this.form;
      let send = phone ? this.$api.sendPhoneCode : this.$api.sendEmailCode;
      send({ phone, email }).then((e) => {
        if (e.code === 200) {
          this.step = 1;
          this.time = 60;
          const time = setInterval(() => {
            this.time--;
            this.time || clearInterval(time);
          }, 1000);
        }
      });
    },
    submit() {
      let { email, phone, password } = this.form;
      let { code } = this;
      this.$api
        .register({ account: phone || email, password: password, code: code })
        .then((resolve) => {
          if (resolve.code === 200) {
            this.$message.success("注册成功");
            this.$emit("registerSubmit");
          }
        });
    },
  },
};
</script>
<style lang="scss">
@import "../../style/form";
a {
  text-decoration: none;
}
</style>
<style scoped lang="scss">
.register-container::v-deep {
  width: 409px;
  background: #fff;
  padding: 0 40px;
  box-sizing: border-box;
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #c20c0c;
    border-color: #c20c0c;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #c20c0c;
    font-size: 12px;
  }
  .el-checkbox__label {
    font-size: 12px !important;
  }
  .el-form-item__label {
    color: #333333;
    font-weight: 600;
    font-size: 16px;
  }
}
.head {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  .t {
    font-weight: 600;
    color: #333333;
    font-size: 16px;
  }
  .el-icon-close {
    position: relative;
    left: 20px;
    font-size: 18px;
  }
}
.bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 19px;
  .to-register {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.14);
    display: inline-block;
    > span {
      color: #c20c0c;
      cursor: pointer;
    }
  }
}
.step0 {
  .account-login-form {
    padding-top: 20px;
    .forget-password {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #a0a2a6;
      align-items: center;
    }
  }
  .quick-login-form {
    padding-top: 20px;
  }

  .get-code {
    width: 116px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c20c0c;
    border-radius: 2px;
    border: 1px solid;
    cursor: pointer;
  }
  .next {
    margin-top: 20px;
  }
}
.step1 {
  .step1-t {
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    margin: 60px 0 10px;
  }
  .step1-tip {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #696b6f;
    margin-bottom: 30px;
    > span {
      color: #c20c0cff;
    }
  }
  .phone-code-time {
    margin: 10px 0 67px;
    > span {
      font-size: 14px;
    }
    .re-send {
      color: #c20c0cff;
      cursor: pointer;
    }
    .time {
      color: #696b6f;
    }
  }
}
.bottom-image {
  margin-top: 17px;
  width: 409px;
  margin-left: -40px;
  display: flex;
  img {
    width: 100%;
  }
}
</style>
