<template>
  <div :style="rowStyle" ref="row" class="com-tab-container">
    <div class="t" v-if="t">{{ t }}</div>
    <div class="buttons">
      <div
        class="btn"
        :style="cellStyle"
        :class="{ current: currentIndex === index }"
        v-for="(item, index) in buttons"
        :key="index"
        @click="$emit('update:currentIndex', index)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    currentIndex: Number,
    t: String,
    buttons: Array,
    rowStyle: Object,
    cellStyle: Object,
  },
  mounted() {
    // Object.keys(this.rowStyle).forEach(item=>{
    //   this.$refs.row.style[item] = this.rowStyle[item]
    // })
    // Object.keys(this.cellStyle).forEach(item=>{
    //   this.$refs.cell.forEach(cellItem => {
    //     console.log(this.cellStyle[item])
    //     cellItem.style[item] = this.cellStyle[item]
    //   })
    //
    // })
  },
};
</script>
<style scoped lang="scss">
.com-tab-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;

  .t {
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    margin-right: 30px;
  }
}
.buttons {
  display: flex;
}

.btn {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-left: 30px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid #c20c0c;
  }
}
.btn.current {
  border-bottom: 2px solid #c20c0c;
}
</style>
