import { headerList } from "@/api/header";
import { getUnLookNum } from "@/api/comment";
export default {
  state: () => ({
    headerList: null,
    fraction: [
      {
        y: require("../assets/pj/1.png"),
        n: require("../assets/pj/6.png"),
        bool: false,
        t: "极差",
      },
      {
        y: require("../assets/pj/2.png"),
        n: require("../assets/pj/7.png"),
        bool: false,
        t: "失望",
      },
      {
        y: require("../assets/pj/3.png"),
        n: require("../assets/pj/8.png"),
        bool: false,
        t: "一般",
      },
      {
        y: require("../assets/pj/4.png"),
        n: require("../assets/pj/9.png"),
        bool: false,
        t: "满意",
      },
      {
        y: require("../assets/pj/5.png"),
        n: require("../assets/pj/10.png"),
        bool: false,
        t: "惊喜",
      },
    ],
    unLookNum: 0,
    //默认头像
    avatar: require("../assets/avatar.gif"),
  }),
  mutations: {
    SET_HEADER_LIST(state, data) {
      state.headerList = data;
    },
    SET_LOOK_NUM(state, data) {
      state.unLookNum = data;
    },
  },
  actions: {
    GetSendHeaderList({ commit }) {
      headerList().then((resolve) => {
        commit("SET_HEADER_LIST", resolve.data.records);
      });
    },
    GetUnLookNum({ commit }) {
      getUnLookNum().then((resolve) => {
        if (resolve.code === 200) commit("SET_LOOK_NUM", resolve.data);
      });
    },
  },
  getters: {
    avatar: (state) => state.avatar,
  },
};
