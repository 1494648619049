<template>
  <div class="login-container" v-loading="loading">
    <div class="head">
      <com-tab
        :rowStyle="{ margin: 0 }"
        :cellStyle="{
          margin: '0',
          height: '60px',
          lineHeight: '60px',
          width: '78px',
          textAlign: 'center',
        }"
        :buttons="['账户登录', '快捷登录']"
        :currentIndex.sync="currentIndex"
      >
      </com-tab>
      <div class="close" @click="$emit('close')">
        <i class="el-icon-close" />
      </div>
    </div>

    <div class="main">
      <!--    账号登陆   -->
      <el-form
        :model="accountLoginForm"
        v-if="currentIndex === 0"
        class="account-login-form"
      >
        <el-form-item prop="account">
          <!--          <com-input v-model="accountLoginForm.account" :icon="require('../../assets/u.png')"></com-input>-->
          <el-input
            class="diy-input"
            v-model="accountLoginForm.account"
            placeholder="支持邮箱/手机号码登录"
          >
            <span
              slot="prefix"
              style="
                margin-left: 10px;
                height: 100%;
                display: flex;
                align-items: center;
              "
              ><img alt="" style="width: 15px" src="../../assets/u.png"
            /></span>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            class="diy-input password-input"
            v-model="accountLoginForm.password"
            placeholder="密码"
            type="password"
          >
            <span
              slot="prefix"
              style="
                margin-left: 10px;
                height: 100%;
                display: flex;
                align-items: center;
              "
              ><img style="width: 15px" src="../../assets/s.png"
            /></span>
            <span
              slot="suffix"
              style="cursor: pointer"
              @click="$router.push('/repassword')"
              >| 忘记密码</span
            >
          </el-input>
        </el-form-item>

        <el-form-item>
          <!--          <el-button type="primary" @click="submitForm('numberValidateForm')">登录</el-button>-->
          <com-button size="big" @click="accountLoginFormSubmit"
            >登录</com-button
          >
        </el-form-item>

        <!--        <el-form-item>-->
        <div class="bottom">
          <el-checkbox v-model="savePassword">记住密码</el-checkbox>
          <span class="to-register">
            没有账户？<span @click="$emit('toRegister')">立即注册</span></span
          >
        </div>
        <!--        </el-form-item>-->
      </el-form>

      <!--    快捷登陆   -->
      <el-form
        :model="quickLoginForm"
        v-if="currentIndex === 1"
        class="quick-login-form"
      >
        <el-form-item prop="account">
          <el-input
            class="diy-input"
            v-model="quickLoginForm.phone"
            placeholder="请输入手机号"
          >
            <span
              slot="prefix"
              style="
                margin-left: 10px;
                height: 100%;
                display: flex;
                align-items: center;
              "
              ><img style="width: 15px" src="../../assets/phone1.png"
            /></span>
          </el-input>
        </el-form-item>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <el-form-item prop="password" style="width: 196px">
            <el-input
              class="diy-input"
              v-model="quickLoginForm.code"
              placeholder="请输入验证码"
            >
              <span
                slot="prefix"
                style="
                  margin-left: 10px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                "
                ><img style="width: 15px" src="../../assets/code.png"
              /></span>
            </el-input>
          </el-form-item>
          <div class="get-code" @click="getCode" v-if="time === 0">
            获取验证码
          </div>
          <div v-else class="for-time">{{ time }}s</div>
        </div>
        <el-form-item>
          <com-button size="big" @click="quickLoginFormSubmit">登录</com-button>
        </el-form-item>

        <div class="bottom">
          <span></span>
          <span class="to-register">
            没有账户？<span @click="$emit('toRegister')">立即注册</span></span
          >
        </div>
      </el-form>
    </div>
    <div class="bottom-image">
      <img src="../../assets/login.png" />
    </div>
  </div>
</template>
<script>
import comTab from "@/components/com/com-tab";
import comButton from "@/components/com/com-button";
import { isMobile, isEmail, validatenull } from "@/util/validate";
// import {mapGetters} from 'vuex'
import { getStore } from "@/util/store";
export default {
  components: {
    comTab,
    comButton,
  },
  data() {
    return {
      loading: false,
      currentIndex: 0,
      accountLoginForm: {
        account: "",
        password: "",
      },
      quickLoginForm: {
        phone: "",
        code: "",
      },
      savePassword: false,
      time: 0,
    };
  },
  computed: {
    // ...mapGetters(['getPassword'])
    getPassword() {
      return getStore({ name: "password" });
    },
  },
  methods: {
    accountLoginFormSubmit() {
      let { account, password } = this.accountLoginForm;
      if (validatenull(account) || validatenull(password)) {
        this.$message.error("请输入账号密码");
        return;
      }
      if (!isMobile(account) && !isEmail(account)) {
        this.$message.error("请输入正确的手机号或邮箱");
        return;
      }
      this.loading = true;
      this.$api
        .login({ account, password })
        .then((e) => {
          this.loading = false;
          if (e.code === 200) {
            this.$store.commit("SET_TOKEN", e.data.token);
            this.$store.dispatch("getUserInfo");
            this.$emit("accountSubmit", e.data);
            this.isSavePassword();
          }
        })
        .catch(() => (this.loading = false));
    },
    quickLoginFormSubmit() {
      let { phone, code } = this.quickLoginForm;
      if (!isMobile(phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (validatenull(code)) {
        this.$message.error("请输入正确的验证码");
        return;
      }
      this.loading = true;
      this.$api
        .loginFast({ account: phone, code })
        .then((e) => {
          this.loading = false;
          if (e.code === 200) {
            this.$store.commit("SET_TOKEN", e.data.token);
            this.$store.dispatch("getUserInfo");
            this.$emit("accountSubmit", e.data);
          }
        })
        .catch(() => (this.loading = false));
    },
    getCode() {
      let { phone } = this.quickLoginForm;
      if (!isMobile(phone)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      this.$api.sendPhoneCodeForget({ phone }).then((resolve) => {
        console.log(resolve);
        if (resolve.code === 200) {
          this.$message.success("发送成功");
          this.time = 60;
          let time = setInterval(() => {
            this.time = this.time - 1;
            this.time || clearInterval(time);
          }, 1000);
        }
      });
    },
    isSavePassword() {
      // console.log(this.savePassword)
      if (this.savePassword) {
        let { account, password } = this.accountLoginForm;
        this.$store.commit("SAVE_PASSWORD", { account, password });
      } else {
        this.$store.commit("REMOVE_PASSWORD");
      }
    },
  },
  mounted() {
    console.log(this.getPassword);
    if (this.getPassword) {
      let { account, password } = this.getPassword;
      this.accountLoginForm.account = account;
      this.accountLoginForm.password = password;
      this.savePassword = true;
    }
  },
};
</script>
<style lang="scss">
@import "../../style/form";
</style>
<style scoped lang="scss">
.login-container::v-deep {
  width: 409px;
  background: #fff;
  padding: 0 40px;
  box-sizing: border-box;
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #c20c0c;
    border-color: #c20c0c;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #c20c0c;
  }
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  //box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.14);
  .el-icon-close {
    position: relative;
    left: 20px;
    font-size: 18px;
    cursor: pointer;
    color: #333333ff;
  }
}
.account-login-form {
  padding-top: 20px;
  .forget-password {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    align-items: center;
  }
}
.quick-login-form {
  padding-top: 20px;
}
.bottom {
  display: flex;
  justify-content: space-between;
  .to-register {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    text-shadow: 0 2px 15px rgba(0, 0, 0, 0.14);
    display: inline-block;
    > span {
      color: #c20c0c;
      cursor: pointer;
    }
  }
}
.get-code {
  width: 116px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c20c0c;
  border-radius: 2px;
  border: 1px solid;
  cursor: pointer;
}
.for-time {
  width: 116px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #d6d6d6;
  border-radius: 2px;
  color: #ffffff;
}
.bottom-image {
  margin-top: 17px;
  width: 409px;
  margin-left: -40px;
  display: flex;
  img {
    width: 100%;
  }
}
.password-input::v-deep{
  input{
    padding-right: 70px;
  }
}
</style>
