<template>
  <header class="header-container">
    <div class="main">
      <div class="logo"><img src="../assets/logo/header_logo.png" /></div>

      <nav style="opacity: 0; position: fixed; top: -100%" ref="navShowNumMb">
        <span v-for="(item, index) in navList" :key="index">
          <span class="nav-btn">{{ item.name }}</span>
        </span>
      </nav>

      <nav @mouseover.stop="" v-if="navShowNum">
        <span
          v-for="(item, index) in frontList"
          :key="index"
          :ref="`navBtn${index}`"
        >
          <router-link v-if="index === 0" :to="item.path">
            <span
              @mouseover="over(index)"
              class="nav-btn"
              :class="{ current: current === index }"
              >{{ item.name }}</span
            >
          </router-link>

          <router-link v-else :to="`/muster/${item.id}?name=${item.name}`">
            <span
              @mouseover="over(index)"
              class="nav-btn"
              :class="{ current: current === index }"
              >{{ item.name }}</span
            >
          </router-link>
        </span>
        <span>
          <el-popover
            popper-class="diy-popover"
            v-if="moreList.length > 0"
            placement="bottom"
            width="154"
            trigger="hover"
          >
            <div class="popover" @mouseover.stop>
              <!--                :ref="`navBtn${index + frontList.length}`"-->
              <span v-for="(item, index) in moreList" :key="index">
                <router-link :to="`/muster/${item.id}?name=${item.name}`">
                  <span
                    class="popover-btn"
                    @mouseover="over(frontList.length)"
                    :class="{
                      'more-current': current === index + frontList.length,
                    }"
                    >{{ item.name }}</span
                  >
                </router-link>
              </span>
            </div>
            <span
              slot="reference"
              @mouseover="over(frontList.length)"
              :ref="`navBtn${frontList.length}`"
              class="nav-btn"
              >更多</span
            >
          </el-popover>
        </span>
        <div
          class="current_bottom"
          v-if="current !== -1"
          :style="`left:${offsetCurrentLeft};`"
        ></div>
      </nav>

      <div class="search">
        <input
          v-model="search"
          @keydown.enter="onSearch"
          type="text"
          placeholder="请输入歌曲、关键字"
        />
        <img @click="onSearch" src="../../src/assets/search.png" />
      </div>

      <!-- 上传音乐 -->
      <div class="upload" @click="$router.push('/upload')" v-if="userInfo">
        <img src="../../src/assets/upload.png" />
        创作中心
      </div>

      <el-popover
        v-if="userInfo"
        popper-class="diy-popover"
        placement="bottom-start"
        width="170"
        trigger="hover"
      >
        <div class="popover">
          <div class="user-popover-btn" @click="$router.push('/personal')">
            <div class="icon"><img src="../assets/header/1.png" /></div>
            个人中心
          </div>
          <div class="user-popover-btn" @click="$router.push('/information')">
            <div class="icon"><img src="../assets/header/2.png" /></div>
            消息中心<span class="suffix" v-if="unLookNum !== 0">{{
              unLookNum
            }}</span>
          </div>
          <div class="user-popover-btn" @click="$router.push('/set')">
            <div class="icon"><img src="../assets/header/3.png" /></div>
            个人设置
          </div>
          <div class="user-popover-btn" @click="logout">
            <div class="icon"><img src="../assets/header/4.png" /></div>
            退出
          </div>
        </div>
        <div class="user" slot="reference">
          <img v-if="userInfo.icon" :src="userInfo.icon" />
          <img v-else :src="avatar" />
        </div>
      </el-popover>

      <div class="login" v-else>
        <span @click="login">登录</span> /
        <span @click="register">注册</span>
      </div>
    </div>

    <com-mask :visible="showLogin">
      <form-login
        @close="showLogin = false"
        @accountSubmit="accountSubmit"
        @toRegister="toRegister"
      ></form-login>
    </com-mask>

    <com-mask :visible="showRegister">
      <form-register
        @close="showRegister = false"
        @registerSubmit="registerSubmit"
        @toLogin="toLogin"
      ></form-register>
    </com-mask>
  </header>
</template>
<script>
import comMask from "@/components/com/com-mask";
import formLogin from "@/components/form/form-login";
import formRegister from "@/components/form/form-register";
import { mapGetters } from "vuex";
export default {
  components: {
    formRegister,
    comMask,
    formLogin,
  },
  data() {
    return {
      search: "",
      current: 0,
      oldCurrent: 0,
      offsetCurrentLeft: 0,
      navShowNum: null,
      nav: [
        {
          name: "首页",
          path: "/home",
          view: 1,
        },
      ],
      childNavList: [],
      showLogin: false,
      showRegister: false,
    };
  },
  computed: {
    ...mapGetters({
      avatar: "avatar",
    }),
    unLookNum() {
      return this.$store.state.com.unLookNum;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    moreList() {
      // return this.navList.filter(item=>item.view===0)
      return this.navList.slice(this.navShowNum);
    },
    frontList() {
      // return this.navList.filter(item=>item.view===1)
      return this.navList.slice(0, this.navShowNum);
    },

    navList() {
      let list = this.$store.state.com.headerList || [];
      // console.log(Array.isArray(list))
      list = list.sort((a, b) => {
        return b.view - a.view;
      });
      // console.log(list);
      let res;
      if (list) {
        // list = list.filter((item) => item.view === 1);
        res = [...this.nav, ...list];
      } else {
        res = this.nav;
      }
      // console.log(res);
      return res;
    },
  },
  watch: {
    $route() {
      this.getCurrent();
    },
    navList: {
      immediate: true,
      handler() {
        // function fn() {
        //   let navShowNumMb = this.$refs.navShowNumMb;
        //   // console.log(navShowNumMb.children.length);
        //   // let navWidth = navShowNumMb.offsetWidth
        //   let navWidth = 600;
        //   let sum = 0;
        //   let btns = navShowNumMb.children;
        //   let navShowNum = btns.length;
        //   for (let i = 0, len = btns.length; i < len; i++) {
        //     console.log(btns[i].offsetWidth);
        //     sum += btns[i].offsetWidth;
        //     if (sum >= navWidth) {
        //       navShowNum = i;
        //       break;
        //     }
        //   }
        //   this.navShowNum = navShowNum;
        //   this.getCurrent();
        //   this.bindDomOn();
        // }
        function fn() {
          let list = this.navList;
          let len = list.filter((item) => item.view === 1).length + 1; //加一是加上首页
          // console.log(list.filter((item) => item.view === 1))
          this.navShowNum = len - 1;

          this.getCurrent();
          this.bindDomOn();
          let index =
            this.current > this.navShowNum ? this.navShowNum : this.current;
          this.$nextTick().then(() => {
            this.over(index);
          });
        }
        this.$nextTick().then(() => {
          this.$refs.navShowNumMb
            ? fn.call(this)
            : (this.computedNavShowNum = fn);
        });
      },
    },
  },
  methods: {
    over(index) {
      if (index === -1) return;
      let ref = this.$refs[`navBtn${index}`];
      let dom = Array.isArray(ref)
        ? this.$refs[`navBtn${index}`][0]
        : this.$refs[`navBtn${index}`];
      if (!dom) return;
      this.offsetleft = dom.offsetLeft + 20 + "px";
      this.offsetCurrentLeft =
        dom.offsetLeft + (dom.offsetWidth - 35) / 2 + "px";
    },
    getCurrent() {
      let index = this.navList.findIndex((item) => {
        if (item.path) {
          return item.path === this.$route.path;
        } else {
          return item.id + "" === this.$route.params.id;
        }
      });
      this.oldCurrent = index;
      this.current = index;
    },
    bindDomOn() {
      document.addEventListener("mouseover", () => {
        this.current = this.oldCurrent;
        if (this.oldCurrent > this.frontList.length) {
          this.over(this.frontList.length);
        } else {
          this.over(this.current);
        }
      });
    },
    login() {
      this.showLogin = true;
    },
    logout() {
      this.$api.logout();
      this.$store.commit("REMOVE_USERIFNO");
    },
    register() {
      this.showRegister = true;
    },
    onSearch() {
      this.$router.push(`/search?search=${this.search}`);
    },
    accountSubmit() {
      this.showLogin = false;
    },
    registerSubmit() {
      this.showRegister = false;
      this.showLogin = true;
    },
    toRegister() {
      this.showLogin = false;
      this.showRegister = true;
    },
    toLogin() {
      this.showLogin = true;
      this.showRegister = false;
    },
  },
  mounted() {
    this.computedNavShowNum && this.computedNavShowNum();
  },
};
</script>
<style lang="scss">
.diy-popover {
  border-radius: 0;
  padding: 10px 0 !important;
  margin-top: 0 !important;

  .popper__arrow {
    //top: -8px;
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
@import "../style/css";
$childHeight: 66px;
$headerHeight: 72px;
a {
  text-decoration: none;
}

header {
  background: #ffffff;
  box-shadow: 0 3px 4px 0 #f0f0f0;
  height: $headerHeight;
  //margin-bottom: $childHeight;
  .main {
    width: $containerWidth;
    margin: auto;
    height: $headerHeight;
    display: flex;
    align-items: center;
  }
  .logo {
    //margin-right: 97px;
    width: 221px;
    height: 50px;
    img {
      height: 100%;
    }
    //line-height: 50px;
    //color: #C20C0C;
    //font-size: 30px;
    //letter-spacing: 3px;
  }
  span {
    //margin-right: 10px;
  }
}
nav {
  flex-grow: 1;
  //width: 700px;
  height: 100%;
}
.nav-btn {
  color: #696b6fff;
  font-weight: 400;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  margin: 0 12px;
  display: inline-block;
  height: $headerHeight;
  line-height: $headerHeight;
  padding: 0 8px;
  &:hover {
    color: #333;
    //font-weight: 600;
  }
}
.popover-btn {
  width: 100%;
  display: inline-block;
  height: 35px;
  line-height: 35px;
  padding-left: 31px;
  box-sizing: border-box;
  font-size: 12px;
  //color: #FFFFFF;
  color: #696b6f;
}
.popover-btn:hover {
  background: #c20c0c;
  color: #ffffff;
}
.current {
  color: #333;
  font-weight: 700;
}
.more-current {
  background: #c20c0c;
  color: #fff;
}
.child-nav {
  height: 66px;
  position: absolute;
  top: $headerHeight;
  //border: 1px solid red;
  transition: left 0.5s;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border-radius: 15px;
    background: #f8f8f8;
    margin-right: 40px;
    color: #333333;
    font-size: 13px;
  }
}
.current_bottom {
  transition: left 0.5s;
  height: 2px;
  width: 35px;
  background: red;
  position: absolute;
  top: 70px;
}

/*
  搜索
*/
.search {
  width: 224px;
  height: 37px;
  background: #f5f5f5;
  border-radius: 2px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > input {
    border: 0;
    background: #f5f5f5;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  > input:focus {
    outline: 0;
  }
  ::-webkit-input-placeholder,
  ::-moz-placeholder ::-ms-input-placeholder {
    color: #a0a2a6;
  }
  img {
    width: 20px;
    cursor: pointer;
  }
}

.upload {
  height: 37px;
  line-height: 37px;
  border-radius: 2px;
  border: 1px solid #a0a2a6;
  padding: 0 10px;
  margin-left: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #696b6f;
  img {
    width: 20px;
    margin-right: 5px;
  }
}
.login {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #696b6f;
  margin-left: 72px;
  > span {
    cursor: pointer;
  }
}
.user {
  height: 72px;
  margin-left: 72px;
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
.user-popover-btn {
  height: 35px;
  line-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  > .icon {
    width: 20px;
    height: 20px;
    margin-left: 16px;
    margin-right: 15px;
    display: flex;
    img {
      width: 100%;
    }
  }
  .suffix {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    min-width: 22px;
    text-align: center;
    background: #c20c0c;
    border-radius: 11px;
    color: #fff;
    font-size: 16px;
    margin-left: 5px;
    padding: 0 6px;
    box-sizing: border-box;
    transform: scale(0.6);
  }
}
.user-popover-btn:hover {
  color: #c20c0c;
}
</style>
