import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as Api from "@/api/index";

Vue.prototype.$api = Api;
import VueCropper from "vue-cropper";
Vue.use(VueCropper);
import {
  Button,
  Form,
  FormItem,
  Input,
  Row,
  Col,
  Upload,
  Checkbox,
  Pagination,
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Radio,
  Tooltip,
  Popover,
  Carousel,
  carouselItem,
  Dialog,
  InfiniteScroll,
  Message,
  Loading,
  MessageBox,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Button)
  .use(Form)
  .use(FormItem)
  .use(Input)
  .use(Row)
  .use(Col)
  .use(Upload)
  .use(Checkbox)
  .use(Pagination)
  .use(Table)
  .use(TableColumn)
  .use(Select)
  .use(Option)
  .use(DatePicker)
  .use(Radio)
  .use(Tooltip)
  .use(Popover)
  .use(Carousel)
  .use(Dialog)
  .use(carouselItem)
  .use(InfiniteScroll)
  .use(Loading)
  .use(Loading.directive);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

import { resetLoading } from "@/util/loading";
resetLoading(Vue);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
