import axios from "@/util/axios";

/*
 * 获取歌曲列表
 * */
export const singleList = (params) => {
  return axios({
    url: "/api/bizmusic/list",
    method: "GET",
    params: {
      ...params,
    },
  });
};
/*
 * 获取歌曲列表
 * */
// export const singleSongList = (params) => {
//   return axios({
//     url: "/api/bizlistmusic/list",
//     method: "GET",
//     params: {
//       ...params,
//     },
//   });
// };

/*
 * 获取歌单列表
 * */
export const songsList = (params) => {
  return axios({
    url: "/api/bizlist/list",
    method: "GET",
    params: {
      ...params,
    },
  });
};
/*
 * 获取某个歌单里的歌曲列表
 * */
export const bySongsGetSingleList = (params) => {
  !Object.hasOwnProperty.call(params, "shelves") && (params.shelves = 1);
  return axios({
    url: "/api/bizlistmusic/list",
    method: "GET",
    params: {
      shelves: 1,
      ...params,
    },
  });
};
/*
 * 获取歌曲详情
 * */
export const singleDetail = (params) => {
  return axios({
    url: "/api/bizmusic/detail",
    method: "GET",
    params: {
      ...params,
    },
  });
};
/*
 * 获取歌曲详情和歌单
 * */
export const singleDetailAndSongs = (params) => {
  return axios({
    url: "/api/bizlistmusic/detail",
    method: "GET",
    params: {
      ...params,
    },
  });
};
/*
 * 获取歌单详情
 * */
export const songsDetail = (params) => {
  return axios({
    url: "/api/bizlist/detail",
    method: "GET",
    params: {
      ...params,
    },
  });
};

/*
 * 删除歌曲
 * */
export const removeSingle = (params) => {
  return axios({
    url: "/api/bizmusic/remove",
    method: "POST",
    params: {
      ...params,
    },
  });
};

/*
 * 删除歌单
 * */
export const removeSongs = (params) => {
  return axios({
    url: "/api/bizlist/remove",
    method: "POST",
    params: {
      ...params,
    },
  });
};

/*
 * 上传歌曲
 * */
export const uploadSingle = (params) => {
  return axios({
    url: "/api/bizlistmusic/submit",
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    data: {
      ...params,
    },
  });
};
/*
 * 修改歌曲
 * */
export const upSingle = (params) => {
  return axios({
    url: "/api/bizlistmusic/music",
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    data: {
      ...params,
    },
  });
};
/*
 * 上传歌单和歌曲
 * */
export const uploadSongsSingle = (params) => {
  return axios({
    url: "/api/bizlistmusic/createUpdate",
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    data: {
      ...params,
    },
  });
};
/*
 * 上传歌单和歌曲
 * */
export const uploadSongs = (params) => {
  return axios({
    url: "/api/bizlist/save",
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    data: {
      ...params,
    },
  });
};

/*
 * 编辑歌单
 * */
export const upSongs = (params) => {
  return axios({
    url: "/api/bizlist/submit",
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    data: {
      ...params,
    },
  });
};
/*
 * 保存歌曲到歌单
 * */

export const saveMusicToSongs = (params) => {
  return axios({
    url: "/api/bizlistmusic/save",
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    data: {
      ...params,
    },
  });
};
/*
 * 将歌曲移出歌单
 * */
export const outSongsMusic = (params) => {
  return axios({
    url: "/api/bizlistmusic/remove",
    header: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    params: {
      ...params,
    },
  });
};
/*
 * 获取周月季音乐排行榜
 * */
export const sendRanking = (params) => {
  return axios({
    url: "/api/search/musicRanking",
    method: "GET",
    params: {
      ...params,
    },
  });
};
/*
 * 获取周月季单个音乐排行榜
 * */
export const sendOnlyRanking = (type, params) => {
  let url = "";
  switch (type) {
    case "week":
      url = "/api/search/musicRankingWeek";
      break;
    case "month":
      url = "/api/search/musicRankingMonth";
      break;
    case "quarter":
      url = "/api/search/musicRankingQuarter";
      break;
  }
  return axios({
    url: url,
    method: "GET",
    params: {
      ...params,
    },
  });
};
/*
 * 获取周月季单个音乐排行榜
 * */
export const sendOnlySongsRanking = (type, params) => {
  let url = "";
  switch (type) {
    // case 'week':
    //   url = '/api/search/musicRankingWeek'
    //   break
    // case 'month':
    //   url = '/api/search/musicRankingMonth'
    //   break
    case "quarter":
      url = "/api/search/listRankingQuarter";
      break;
  }
  return axios({
    url: url,
    method: "GET",
    params: {
      ...params,
    },
  });
};
/*
 * 开始播放计数
 * */
export const sendPlayCount = (params) => {
  return axios({
    // url: "/api/search/times",
    url: "/api/search/play",
    method: "POST",
    params: {
      ...params,
    },
  });
};
/*
 * 持续播放计数
 * */
export const timerSendPlayCount = (params) => {
  return axios({
    url: "/api/search/heart",
    method: "POST",
    params: {
      ...params,
    },
  });
};

/*
 * 通过名称搜索歌单或歌曲
 * */
export const byNameSearchList = (params) => {
  return axios({
    url: "/api/search/engine",
    method: "GET",
    params: {
      ...params,
    },
  });
};
