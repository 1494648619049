import axios from "@/util/axios";
/*
 * 获取轮播图
 * */

export const sendBanner = (params) => {
  return axios({
    url: "/api/bizconfig/list",
    method: "GET",
    params: {
      ...params,
    },
  });
};

/*
 * 获取联系我们的信息
 * */

export const sendGetUS = (params) => {
  return axios({
    url: "/api/bizcontact/list",
    method: "GET",
    params: {
      ...params,
    },
  });
};

/*
 * 提交投诉建议
 * */

export const sendSubmitComplaint = (params) => {
  return axios({
    url: "/api/bizcomplaint/save",
    method: "POST",
    data: {
      ...params,
    },
  });
};

/*
 * 上传图片
 * */

export const uploadImage = (file) => {
  let bytes = window.atob(file.split(",")[1]); //去掉url的头，并转换为byte
  //处理异常,将ascii码小于0的转换为大于0
  let ab = new ArrayBuffer(bytes.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  let blob = new Blob([ab], { type: "image/jpeg" });
  let param = new FormData(); // 创建form对象
  param.append("file", blob, "mo-ren.jpg"); // 通过append向form对象添加数据

  return axios({
    headers: { "Content-Type": "multipart/form-data;" },
    url: "/api/oss/upload",
    method: "POST",
    data: param,
  });
};
