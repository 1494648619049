import axios from "@/util/axios";
/*
 * 获取评论列表
 * */

export const commentList = (params) => {
  return axios({
    url: "/api/bizcomment/list",
    method: "GET",
    params: {
      ...params,
    },
  });
};

/*
 * 保存评价
 * */

export const saveComment = (params) => {
  return axios({
    url: "/api/bizcomment/submit",
    method: "POST",
    data: {
      ...params,
    },
  });
};

/*
 * 获取该用户是否评价过
 * */

export const getIsComment = (params) => {
  return axios({
    url: "/api/bizcomment/detail",
    method: "GET",
    params: {
      ...params,
    },
  });
};

/*
 * 获取未看的评论数量
 * */

export const getUnLookNum = (params) => {
  return axios({
    url: "/api/bizcomment/num",
    method: "GET",
    params: {
      ...params,
    },
  });
};
/*
 * 重置未看评论数量
 * */

export const resetUnLookNum = (params) => {
  return axios({
    url: "/api/bizcomment/view",
    method: "PUT",
    params: {
      ...params,
    },
  });
};
