<template>
  <div v-if="visible" class="mask">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    visible: Boolean,
  },
};
</script>
<style scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
