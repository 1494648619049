import { setStore, removeStore, getStore } from "@/util/store";
import { setToken, removeToken } from "@/util/auto";
import { sendUserInfo } from "@/api/user";
// const userInfo = getStore({ name: "userInfo", type: "session" })
//   ? JSON.parse(getStore({ name: "userInfo", type: "session" }))
//   : null;

export default {
  state: () => ({
    userInfo: null,
  }),
  mutations: {
    SET_TOKEN: (state, token) => {
      setToken(token);
      state.token = token;
      setStore({ name: "JSESSIONID", content: state.token, type: "session" });
    },

    SET_USERIFNO(state, userInfo) {
      // console.log(userInfo);
      state.userInfo = userInfo;
    },
    REMOVE_USERIFNO(state) {
      removeToken();
      removeStore({ name: "userInfo", type: "session" });
      removeStore({ name: "JSESSIONID", type: "session" });
      state.userInfo = null;
    },
    SAVE_PASSWORD(state, d) {
      setStore({ name: "password", content: d });
    },
    REMOVE_PASSWORD() {
      removeStore({ name: "password" });
    },
  },
  actions: {
    async getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        sendUserInfo()
          .then((e) => {
            if (e.code === 200) {
              commit("SET_USERIFNO", e.data);
              resolve();
            } else {
              commit("REMOVE_USERIFNO");
              reject();
            }
          })
          .catch(() => {
            reject();
            commit("REMOVE_USERIFNO");
            // removeToken();
          });
      });
    },
  },
  getters: {
    getPassword() {
      return getStore({ name: "password" });
    },
  },
};
