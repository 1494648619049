<template>
  <div style="width: 100%">
    <audio ref="audio" :muted="muted" @ended="ended">
      您的浏览器不支持 audio 标签。
    </audio>
    <div class="audio">
      <!--   上一首 暂停播放 下一首   -->
      <div class="console">
        <div class="last" @click="audioLast">
          <img src="../assets/audio/last.png" />
        </div>
        <div class="ps">
          <img v-if="play" @click="audioStop" src="../assets/audio/stop.png" />
          <img v-else @click="audioPlay" src="../assets/audio/play.png" />
        </div>
        <div class="next" @click="audioNext">
          <img src="../assets/audio/next.png" />
        </div>
      </div>

      <div class="info">
        <div class="image" :class="{ rotateAlways: play }">
          <img
            v-if="playIndex && list[playIndex].image"
            :src="list[playIndex].image"
          />
          <img v-else src="../assets/music.png" />
        </div>
        <div class="name-progress">
          <div class="name" v-if="playIndex">
            {{ list[playIndex].name }}
            <span>- {{ list[playIndex].singer }}</span>
          </div>
          <div v-else class="name">暂无</div>
          <div class="progress">
            <el-slider
              @input="upCurrentTimeInput"
              @change="upCurrentTime"
              v-model="currentTime"
              :max="duration || 1"
              :format-tooltip="formatSeconds"
            ></el-slider>
          </div>
        </div>
        <div class="time">
          <span class="at">{{ currentTime | formatTime }}</span>
          /
          <span class="total">{{ duration | formatTime }}</span>
        </div>
      </div>

      <div class="operation">
        <div @click="playType = (playType % 3) + 1">
          <img
            class="play-type"
            v-if="playType === 1"
            src="../assets/audio/xh.png"
          />
          <img
            class="play-type"
            v-if="playType === 2"
            src="../assets/audio/xh1.png"
          />
          <img
            class="play-type"
            v-if="playType === 3"
            src="../assets/audio/xh2.png"
          />
        </div>
        <div class="lb" @click.stop="showLB = !showLB">
          <img v-if="showLB" class="play-type" src="../assets/audio/lb1.png" />
          <img v-else class="play-type" src="../assets/audio/lb.png" />
          <div
            class="lb-box"
            @click.stop
            :style="{ display: showLB ? 'block' : 'none' }"
          >
            <div class="lb-head">
              <span>播放列表</span>
              <span class="lb-del-all" @click="removeList"
                ><span class="icon"><img src="../assets/audio/del.png" /></span
                >清空列表</span
              >
            </div>
            <div class="lb-scroll audio-scroll-style">
              <div
                class="lb-item"
                v-for="(item, index) in listKeys"
                :key="index"
                @click="playIndex = item"
                :class="{ 'current-play': item === playIndex }"
              >
                <span v-if="playIndex == item" class="lb-icon">
                  <img src="../assets/audio/1.gif" alt="" />
                </span>
                <span v-else class="lb-index">{{ index + 1 }}</span>
                <span class="lb-name"> {{ list[item].name }}</span>
                <span class="lb-singer">{{ list[item].singer }}</span>
                <span class="lb-time">{{
                  list[item].duration | formatTime
                }}</span>
                <span
                  class="lb-del"
                  @click.stop="
                    $store.commit('DEL_PLAYLIST', { d: list[item], playIndex })
                  "
                  ><img src="../assets/audio/del.png"
                /></span>
              </div>
            </div>
          </div>
        </div>
        <div @click="onMuted">
          <img class="play-type" v-if="muted" src="../assets/audio/v1.png" />
          <img class="play-type" v-else src="../assets/audio/v.png" />
        </div>
        <div class="volume">
          <el-slider
            @change="upVolume"
            v-model="volume"
            style="width: 100px"
            :max="100"
            :disabled="muted"
          ></el-slider>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Slider } from "element-ui";
let _this;
export default {
  data() {
    return {
      audioSrc: null,
      play: false,
      playIndex: null,
      progress: 0,
      currentTime: 0,
      duration: 0,
      volume: 50,
      muted: false, //是否静音
      showLB: false,
      playType: 1, // 1循环 2单曲循环 3 随机
      sendPlayCountTime: null,
      isDragSlider: [false, false], // 2个true表示拖拽进度
      listKeys: [],
      list: {},
    };
  },
  props: {},
  computed: {
    playId() {
      return this.$store.state.playId;
    },
    upPlayId() {
      let { playId, playDate } = this.$store.state;
      return { playId, playDate };
    },
  },
  watch: {
    playIndex(v) {
      this.$store.commit("SET_PLAYID", this.playIndex);
      v !== null ? this.audioPlay() : this.audioReset();
    },
    upPlayId: {
      immediate: true,
      handler(v) {
        if (this.playIndex === v.playId) {
          v.playId !== null ? this.audioPlay() : this.audioReset();
        } else {
          this.playIndex = v.playId;
        }
      },
    },
    "$store.state.playList": {
      immediate: true,
      handler() {
        let list = this.$store.state.playList;
        this.list = list;
        this.listKeys = Object.keys(list).sort(
          (a, b) => list[b].sort - list[a].sort
        );
      },
    },
  },
  components: {
    elSlider: Slider,
  },
  methods: {
    // 播放
    audioPlay() {
      let _this = this;

      if (!Object.hasOwnProperty.call(this.list, this.playIndex)) {
        this.$store.commit("SET_PLAYINDEX", this.listKeys[0]);
        this.playIndex = this.listKeys[0];
        return;
      }

      if (this.$refs.audio.src !== this.list[this.playIndex].url) {
        this.$refs.audio.src = this.list[this.playIndex].url;
      }

      this.$refs.audio
        .play()
        .then(() => {
          _this.play = true;
          let time = setInterval(() => {
            _this.$refs.audio.paused && clearInterval(time);
            if (!_this.isDragSlider[1]) {
              _this.isDragSlider[0] = false;
              _this.currentTime = _this.$refs.audio.currentTime;
            }
            _this.duration = _this.$refs.audio.duration;
          }, 30);

          this.sendCountPlay();
        })
        .catch((err) => {
          console.log(err);
          // this.$message({
          //   message: err,
          //   type: "warning",
          // });
        });
    },
    //暂停
    audioStop() {
      this.$refs.audio.pause();
      this.play = false;
    },
    //清除播放状态
    audioReset() {
      this.$refs.audio && (this.$refs.audio.src = "");
      this.currentTime = 0;
      this.duration = 0;
      this.play = false;
      this.isDragSlider = [false, false];
    },

    //下一首
    audioNext() {
      let keys = this.listKeys;
      let index = keys.findIndex((item) => item == this.playIndex);
      if (keys[index + 1]) {
        this.playIndex = keys[index + 1];
      } else {
        this.playIndex = keys[0];
      }
    },
    //上一首
    audioLast() {
      let keys = this.listKeys;
      let index = keys.findIndex((item) => item == this.playIndex);
      if (keys[index - 1]) {
        this.playIndex = keys[index - 1];
      } else {
        this.$message({
          message: "亲，这是第一首",
          type: "warning",
        });
      }
    },

    //播放计数 - 开始播放发送一次请求
    sendCountPlay() {
      let playObj = this.list[this.playIndex];
      let listId = playObj.listId || null;
      let atId = playObj ? playObj.id : null;
      atId &&
        this.$api.sendPlayCount({ musicId: atId, listId }).then((resolve) => {
          resolve.code === 100 &&
            this.timerSendCountPlay(playObj, resolve.data);
        });
    },

    //播放计数 - 播放状态持续间隔发送
    timerSendCountPlay(obj, token) {
      // let newName = obj.url.substring(obj.url.lastIndexOf("/") + 1);
      let newName = obj.url.split("/").pop();
      let time = 6000;

      clearTimeout(this.sendPlayCountTime);

      if (!this.$refs.audio.paused) {
        this.sendPlayCountTime = setTimeout(() => {
          this.$api
            .timerSendPlayCount({ newName: newName, token: token })
            .then((resolve) => {
              resolve.code === 201 && this.timerSendCountPlay(obj, token);
              resolve.code === 100 &&
                this.timerSendCountPlay(obj, resolve.data);
            });
        }, time);
      }
    },

    //静音
    onMuted() {
      let bool = !this.muted;
      this.muted = bool;
      this.$refs.audio.muted = bool;
    },

    //播放进度条值改变触发，用于判断是否处于拖拽状态
    upCurrentTimeInput() {
      if (!this.isDragSlider[0]) {
        this.isDragSlider[0] = true;
      } else {
        this.isDragSlider[1] = true;
      }
    },

    //修改播放进度
    upCurrentTime(e) {
      this.isDragSlider[1] = false;
      this.$refs.audio.src
        ? (this.$refs.audio.currentTime = e)
        : this.audioPlay();
    },
    //修改声音
    upVolume(e) {
      this.$refs.audio.volume = e / 100;
    },

    //播放结束
    ended() {
      this.play = false;
      let playType = this.playType;
      switch (playType) {
        case 1: {
          this.audioNext();
          break;
        }
        case 2:
          this.audioPlay();
          break;
        case 3: {
          let playIndex = this.playIndex;
          let keys = this.listKeys;
          let keyIndex = keys.findIndex((item) => item === playIndex);
          let index = this.randomNum(0, keys.length - 1, keyIndex);
          this.playIndex = keys[index];
          break;
        }
      }
    },

    //清空播放列表
    removeList() {
      this.listKeys.forEach((item) => {
        this.$store.commit("DEL_PLAYLIST", {
          d: this.list[item],
          playIndex: this.playIndex,
        });
      });
    },

    //讲秒数转化为时分秒的格式
    formatSeconds(value) {
      if (Number.isNaN(value)) return "00:00:00";
      let theTime = Math.ceil(value); // 秒
      let middle = 0; // 分
      let hour = 0; // 小时

      if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
          hour = parseInt(middle / 60);
          middle = parseInt(middle % 60);
        }
      }

      let result =
        parseInt(theTime) >= 10
          ? "" + parseInt(theTime)
          : "0" + parseInt(theTime);
      middle = middle >= 10 ? "" + parseInt(middle) : "0" + parseInt(middle);
      hour = hour >= 10 ? "" + parseInt(hour) : "0" + parseInt(hour);

      return hour + ":" + middle + ":" + result;
    },

    //生成从minNum到maxNum的随机数
    randomNum(minNum, maxNum, n) {
      if (minNum === maxNum) return minNum;
      let res = parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      res = n === res ? this.randomNum(minNum, maxNum, n) : res;
      return res;
    },
  },

  filters: {
    formatTime(t) {
      return _this.formatSeconds(t);
    },
  },
  created() {
    _this = this;
  },
  mounted() {
    document.addEventListener("click", () => {
      this.showLB = false;
    });
  },
};
</script>
<style scoped lang="scss">
@import "./src/style/animation";
.audio {
  display: flex;
  align-items: center;
  height: 53px;
  width: 100%;
}
.console {
  display: flex;
  align-items: center;
  height: 37px;
  .last,
  .next {
    width: 15px;
    display: flex;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .ps {
    width: 37px;
    cursor: pointer;
    margin: 0 30px;
    display: flex;
    img {
      width: 100%;
    }
  }
}
.info {
  display: flex;
  height: 100%;
  align-items: center;
  .image {
    width: 40px;
    height: 40px;
    margin-left: 30px;
    margin-right: 25px;
    overflow: hidden;
    border-radius: 50%;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }
  .rotateAlways {
    animation: rotateAlways 5s 0.2s linear infinite forwards;
  }
  .name {
    color: #fff;
    font-size: 12px;
    span {
      color: #a0a2a6;
    }
  }
  .progress::v-deep {
    width: 600px;
    .el-slider__runway {
      //height: 5px;
      margin: 8px 0 !important;
    }
    .el-slider__bar {
      background: linear-gradient(151deg, #c20c0c 0%, #d27c7c 100%) !important;
      //height: 5px;
    }
    .el-slider__button {
      border: 0 !important;
      width: 10px;
      height: 10px;
    }
    .el-slider__runway {
      background: #171717;
    }
  }

  .time {
    font-size: 14px;
    color: #81818191;
    margin-left: 20px;
    .at {
      color: #ffffff;
    }
  }
}
.volume::v-deep {
  .el-slider__runway {
    background: #171717;
  }
  .el-slider__button {
    border: 0 !important;
    width: 10px;
    height: 10px;
  }
  .el-slider__bar {
    background: #c20c0c;
  }
}
.operation {
  flex-grow: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  .play-type {
    width: 19px;
    margin: 0 15px;
    cursor: pointer;
  }
}
.lb {
  position: relative;
  .lb-box {
    position: absolute;
    width: 525px;
    height: 300px;
    background: #333333;
    border-radius: 2px 2px 0px 0px;
    opacity: 0.95;
    bottom: 37px;
    right: 50%;
    .lb-head {
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      border-bottom: #696b6f solid 1px;
      margin: 0 20px;
      .lb-del-all {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon {
          display: flex;
          width: 18px;
          margin-right: 5px;
          img {
            width: 100%;
          }
        }
      }
    }
    .lb-scroll {
      height: 230px;
      overflow: auto;
    }
    .lb-item {
      height: 46px;
      line-height: 46px;
      color: #ffffff;
      display: flex;
      align-items: center;
      padding-left: 50px;
      padding-right: 20px;
      .lb-time {
        width: 100px;
        text-align: right;
        flex-shrink: 0;
      }
      .lb-icon {
        width: 15px;
        height: 15px;
        margin-right: 15px;
        box-sizing: border-box;
        display: flex;
        img {
          width: 100%;
        }
      }
      .lb-index {
        width: 30px;
      }
      .lb-del {
        display: none;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      .lb-name {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .lb-singer {
        width: 82px;
        flex-shrink: 0;
      }
      .lb-del {
        width: 15px;
        height: 15px;
        margin-left: 85px;
        text-align: right;
        flex-shrink: 0;
      }
    }
    .lb-item:hover {
      background: #2a2929;
      .lb-time {
        display: none;
      }
      .lb-del {
        display: flex;
      }
    }

    .current-play {
      background: #2a2929;
    }
  }
}
</style>
