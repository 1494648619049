<template>
  <div>
    <div
      @click="$emit('click')"
      :style="btnStyle"
      class="btn"
      :class="butClass"
    >
      <div>
        <div class="icon" v-if="icon"><img alt="" :src="icon" /></div>
        <div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: "medium", //big / medium / small / mini
    },
    type: {
      type: String,
      default: "deep",
    },
    btnStyle: Object,
    icon: String,
  },
  computed: {
    butClass() {
      let res = "";
      let size = this.size;
      let type = this.type;
      res +=
        size === "big" ||
        size === "medium" ||
        size === "small" ||
        size === "mini"
          ? size
          : "medium";
      res += " ";
      res +=
        type === "deep" ||
        type === "shallow" ||
        type === "white" ||
        type === "text"
          ? type
          : "deep";
      return res;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn {
  /*width: 132px;*/
  display: inline-block;
  height: 42px;
  line-height: 42px;
  text-align: center;
  border-radius: 2px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  cursor: pointer;
  box-sizing: border-box;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    width: 18px;
    display: flex;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }
  &:active {
    opacity: 0.8;
  }
}

/*//big / medium / small / mini*/
.big {
  width: 100% !important;
}

.medium {
  width: 132px;
}

.mini {
  width: auto;
}

.deep {
  /*background: linear-gradient(142deg, #C20C0C 0%, #D27C7C 100%);*/
  background: #c20c0c;
  color: #ffffff;
}

.shallow {
  background: #f8f8f8;
  color: #333333;
}

.white {
  background: #ffffff;
  color: #333333;
  border: 1px solid #a0a2a6;
}

.text {
  float: left;
  color: #c20c0c;
  font-size: 16px;
  margin-right: 30px;
}
</style>
